import { Component, ElementRef, LOCALE_ID, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { AbsoluteScrollStrategy, ConnectedPositioningStrategy, GridColumnDataType, HorizontalAlignment, NoOpScrollStrategy, IGridCellEventArgs, IgxBooleanFilteringOperand, IgxColumnComponent, IgxDateFilteringOperand, IgxDatePickerComponent, IgxDialogComponent, IgxGridComponent, IgxNumberFilteringOperand, IgxSelectComponent, IgxStringFilteringOperand, IRowSelectionEventArgs, OverlaySettings, PositionSettings, VerticalAlignment, IgxGridToolbarComponent, IgxCellTemplateDirective, IgxGridFooterComponent, IgxDialogTitleDirective, IgxLabelDirective, IgxSimpleComboComponent, IgxIconComponent, IgxPrefixDirective, IgxSuffixDirective, IgxFilterCellTemplateDirective, IgxButtonDirective, IgxRippleDirective, IgxInputGroupModule } from '@infragistics/igniteui-angular';
import { scaleInTop, scaleOutBottom } from '@infragistics/igniteui-angular/animations';
import { Router } from "@angular/router";
import { TimestampToDatestanp, toLocalIsoString } from 'src/app/util/DatalexDateTime';
import { sumCharged, sumFee } from 'src/app/util/HelperFunctions';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';
import { DatalexExceptionHandlerService } from 'src/app/services/datalex-exception-handler.service';
import { ActivityLogService } from '../../UI/activity-log-overlay/activity-log.service';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';
import { UserAreaEnum, UserRightTypeEnum } from 'src/app/util/UserRightUtil';
import { DatalexClient, ICaseAccessLogBE, ICaseAccessLogExtendedBE, ICaseShortlistBE, IHourRegistrationBE } from '@datalex-software-as/datalex-client';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { IBaseCancelableBrowserEventArgs } from '@infragistics/igniteui-angular/lib/core/utils';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ContextMenuComponent, CtxItem } from '../../UI/context-menu/context-menu.component';
import { CaseBE } from 'src/app/classes/CaseBE';
import newActivity from 'src/app/classes/Activity/Actvity';
import { DeviceService } from 'src/app/services/device.service';
import { GridFilterInputComponent } from '../../UI/grid-filter-input/grid-filter-input.component';
import { HeadingComponent } from '../../UI/heading/heading.component';
import { FormsModule } from '@angular/forms';
import { HoursRegistrationComponent, IItemSelected } from '../selected-case-page/charging/hours-registration/hours-registration.component';
import { GridItemCountComponent } from '../../UI/grid-item-count/grid-item-count.component';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { FormatTimePipe } from 'src/app/util/pipes/format-time.pipe';
import { CaseStatusEnum } from 'src/app/util/CaseStatusEnum';
import { NorwegianNumberFormatPipe } from "../../../util/pipes/norwegian-number-format.pipe";


@Component({
  selector: 'app-my-cases',
  templateUrl: './my-cases.component.html',
  styleUrls: ['./my-cases.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'nb' }],
  standalone: true,
  imports: [FormatTimePipe, IgxGridComponent, NgIf, IgxGridToolbarComponent, NgTemplateOutlet, IgxColumnComponent, IgxCellTemplateDirective, IgxGridFooterComponent, GridItemCountComponent, ContextMenuComponent, IgxDialogComponent, IgxDialogTitleDirective, HoursRegistrationComponent, IgxLabelDirective, IgxSimpleComboComponent, FormsModule, IgxDatePickerComponent, IgxIconComponent, IgxPrefixDirective, IgxSuffixDirective, HeadingComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent, IgxButtonDirective, IgxRippleDirective, NgClass, IgxInputGroupModule, NorwegianNumberFormatPipe]
})

export class MyCasesComponent implements OnInit, OnDestroy {
  hostElement!: any;
  @ViewChild(IgxSelectComponent, { static: true })
  public igxSelect!: IgxSelectComponent;

  @ViewChild('gridContextMenu')
  public gridContextMenu!: ContextMenuComponent;
  @ViewChild('RegiterHourModal', { read: IgxDialogComponent })
  public RegiterHourModal!: IgxDialogComponent;
  @ViewChild('outlet', { read: ViewContainerRef }) outletRef!: ViewContainerRef;
  @ViewChild('content', { read: TemplateRef }) contentRef!: TemplateRef<any>;

  @ViewChild('datePicker') datePicker!: IgxDatePickerComponent;

  public customOverlaySettings!: OverlaySettings;

  public clickedCellRowData: any;
  public contextmenu = false;
  public contextmenuX = 0;
  public contextmenuY = 0;
  public caseData!: CaseBE;
  ctx_content: CtxItem[][] = [[
    {
      label: "Åpne sak",
      action: () => this.openCase(),
      leftIcon: "open_in_new",
    },
    {
      label: "Se belastninger",
      action: () => this.registerTime(),
      disabled: true,
      leftIcon: "remove_red_eye",
    }]
  ];

  showModal = false;

  selectedUserId!: string;

  hasRightToEditHours: boolean = false;

  setContext() {
    this.ctx_content = [[
      {
        label: "Åpne sak",
        action: () => this.openCase(),
        leftIcon: "open_in_new",
      },
      {
        label: this.rights.canEditHoursCosts() ? "Register tid" : "Se belastninger",
        action: () => this.registerTime(),
        disabled: this.rights.getHoursCostsAccessLevel() === UserRightTypeEnum.NONE,
        leftIcon: this.rights.canEditHoursCosts() ? "edit" : "remove_red_eye",
      }]
    ]
  }

  isCaseClosed(caseStatusTypeId: string) {
    switch (caseStatusTypeId.toUpperCase()) {
      case CaseStatusEnum.Aktiv.toUpperCase():
        return false;
      default:
        return true;
    }
  }

  browserType!: string;
  deviceType: string;

  private subscriptions: Subscription = new Subscription();
  screenWidth!: number;
  screenHeight!: number;
  deviceOrientation!: string;

  isMobile!: boolean;
  isTablet!: boolean;
  isDesktop!: boolean;

  hourRegistrationDetails: IHourRegistrationBE | null = null;

  public upwardPositionSettings: OverlaySettings = {
    positionStrategy: new ConnectedPositioningStrategy({
      horizontalDirection: HorizontalAlignment.Right,
      horizontalStartPoint: HorizontalAlignment.Left,
      verticalDirection: VerticalAlignment.Top,
      verticalStartPoint: VerticalAlignment.Top
    }),
    scrollStrategy: new NoOpScrollStrategy()
  };

  constructor(
    public screen: ScreenDimensionService,
    private dlxEx: DatalexExceptionHandlerService,
    private rights: UserRightsProviderService,
    private als: ActivityLogService,
    public dlxClient: DatalexClient,
    public gms: GridMethodsService,
    public sys: SystemCacheService,
    private router: Router,
    private host: ElementRef,
    public combo: ComboSelectFunctionsService,
    public screenSize: ScreenSizeService,
    public deviceService: DeviceService

  ) {

    this.browserType = this.deviceService.getBrowserType();
    this.deviceType = this.deviceService.getDeviceType();

    this.subscriptions.add(this.screenSize.isMobile$.subscribe(isMobile => {
      this.isMobile = isMobile;
    }));

    this.subscriptions.add(this.screenSize.isTablet$.subscribe(isTablet => {
      this.isTablet = isTablet;
    }));

    this.subscriptions.add(this.screenSize.isDesktop$.subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    }));

    this.hostElement = this.host.nativeElement as HTMLDivElement;

  }
  ngOnDestroy() {
    // Clean up subscriptions to prevent memory leaks
    this.subscriptions.unsubscribe();
  }
  caseRights!: string;
  userRightsLoaded!: boolean
  grid_message: string = "Laster inn dine saker";

  @ViewChild("caseAccesLogGrid", {
    read: IgxGridComponent,
    static: false
  })
  public grid!: IgxGridComponent;
  caseAccessLog: ICaseAccessLogExtendedBE[] = [];

  private _filterValues = new Map<IgxColumnComponent, any>();

  date: Date = new Date();
  getAllActiveCases: boolean = false
  totalCharged: string = "00:00";
  totalFee: string = '0';
  selectedUser = this.sys.userId;


  emptyGridMessage: string = "Ingen saker å vise."

  toggleAllActive() {
    this.getAllActiveCases = !this.getAllActiveCases;
    this.getNewAccessLog(TimestampToDatestanp(this.date.getTime()), this.selectedUser);
  }

  public changeDate(event: any) {
    const input = event;
    const parsedDate = Date.parse(input);
    this.getNewAccessLog(parsedDate);
    this.date = new Date(parsedDate);
  }

  nextDay() {
    this.date = new Date(this.date.setDate(this.date.getDate() + 1));
    const timestamp = this.date.getTime();
    this.getNewAccessLog(timestamp, this.selectedUser);
  }

  prevDay() {
    this.date = new Date(this.date.setDate(this.date.getDate() - 1));
    const timestamp = this.date.getTime();
    this.getNewAccessLog(timestamp, this.selectedUser);
  }


  selectedUserChange(item: string) {
    this.selectedUser = item ? item : this.sys.userId;
    this.getNewAccessLog(+new Date(), this.selectedUser);
  }

  getNewAccessLog(date: number, userId: string = this.selectedUser ?? this.sys.userId) {
    this.dlxClient.GetCaseAccessLogByUserAndDate2(userId, toLocalIsoString(date), this.getAllActiveCases).subscribe({
      next: (caseAccessLog) => {

        this.filteredDatasource = this.caseAccessLog = caseAccessLog;
        this.totalFee = sumFee(caseAccessLog);
        this.totalCharged = sumCharged(caseAccessLog);
        // this.padTime(caseAccessLog, "Logged")
        // this.padTime(caseAccessLog, "Charged")

        if (caseAccessLog.length > 0) {
          this.grid_message = "Slutten av listen";
        }
      },
      error: this.dlxEx.exception.bind(this)
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.deviceService.deviceOrientation$.subscribe((orientation) => {
        this.deviceOrientation = orientation;

      })
    );

    this.subscriptions.add(
      this.deviceService.screenSize$.subscribe((size) => {
        this.screenWidth = size.width;
        this.screenHeight = size.height;
      })
    );

    this.getNewAccessLog(+new Date())
    const positionSettings: PositionSettings = {
      closeAnimation: scaleOutBottom,
      horizontalDirection: HorizontalAlignment.Right,
      horizontalStartPoint: HorizontalAlignment.Left,
      openAnimation: scaleInTop,
      verticalDirection: VerticalAlignment.Bottom,
      verticalStartPoint: VerticalAlignment.Bottom
    };
    this.customOverlaySettings = {
      positionStrategy: new ConnectedPositioningStrategy(
        positionSettings
      ),
      scrollStrategy: new AbsoluteScrollStrategy()
    }


    try {
      this.userRightsLoaded = !!this.sys.userRights;
      this.caseRights = this.rights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessName;
      this.hasRightToEditHours = this.rights.canEditHoursCosts();
    } catch (error) {
      this.sys.isReady.subscribe(() => {
        this.userRightsLoaded = !!this.sys.userRights;
        this.caseRights = this.rights.checkAccess(UserAreaEnum.CASE_MANAGMENT).accessName;
        this.hasRightToEditHours = this.rights.canEditHoursCosts();
      })
    }

  }

  @ViewChild("contextRef", { read: ViewContainerRef }) $ref!: ViewContainerRef;

  openCase() {
    if (!this.ctxTarget) return;
    if (this.caseRights === 'NONE') {
      const activity = newActivity({
        message: `Ingen tilgang på området "Saksbehandling".`,
        type: "failure",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      return
    }

    if (this.caseRights === 'OWN_CASES') {
      this.dlxClient.GetCaseLimited(this.ctxTarget.CaseId).subscribe({
        next: (response) => {
          if (this.rights.checkCaseAccessOwnCases({ sa: response.CaseResponsibleId!, sb: response.CaseHandlerId }, this.sys.loggedinUserContact.Id)) {
            this.router.navigate(['/case', response.Id]);
          } else {

            const activity = newActivity({
              message: `Ingen tilgang, du har kun tilgang til egne saker.`,
              type: "failure",
              timestamp: new Date()
            })

            this.als.appendActivity(activity)

            alert("Du har ikke tilgang til denne saken. Du har kun tilgang til egne saker.")
            return
          }
        }
      })

      return
    }

    this.router.navigate(['/case', this.ctxTarget.CaseId]);

  }

  caseClosedForTimeRegistration: boolean = false;

  registerTime() {
    if (this.ctxTarget) {
      this.dlxClient.GetCase(this.ctxTarget.CaseId, false).subscribe({
        next: (response) => {
          this.clickedCellRowData = new CaseBE(response);


          if (this.isCaseClosed(response.CaseStatusTypeId)) {
            this.caseClosedForTimeRegistration = true;
          }


          this.RegiterHourModal.open();
          const sub = this.RegiterHourModal.closed.subscribe({
            next: () => {
              this.clickedCellRowData = null;
              this.caseClosedForTimeRegistration = false;
            },
            complete: () => {
              sub.unsubscribe();

            }
          })
        }
      })
    }
  }

  public rerender() {
    this.outletRef.clear();
    this.outletRef.createEmbeddedView(this.contentRef);
  }



  updateShortListFavorite(row: ICaseAccessLogExtendedBE, newvalue: boolean) {
    if (newvalue) {
      this.dlxClient.NewCaseShortlist().subscribe({
        next: (r1: ICaseShortlistBE) => {
          r1.CaseId = row.CaseId;
          r1.UserId = row.UserId;

          this.dlxClient.SaveCaseShortlist(r1).subscribe({
            next: () => { this.getNewAccessLog(this.date.getTime()) },
          })
        }
      })
    } else {
      this.dlxClient.DeleteSingleCaseShortlist(row.ShortlistId!).subscribe({
        next: () => { this.getNewAccessLog(this.date.getTime()) }
      });
    }
  }

  filteredDatasource: ICaseAccessLogExtendedBE[] = [];
  tabletFilter: string = ""
  tabletFilterChange() {
    try {
      this.filteredDatasource = this.caseAccessLog.filter(item => {
        const { CaseClient, Title, SA, SB, Number } = item;

        if (!isNaN(parseInt(this.tabletFilter))) {
          return String(Number).includes(this.tabletFilter);
        }

        return CaseClient.toUpperCase().includes(this.tabletFilter.toUpperCase())
          || Title.toUpperCase().includes(this.tabletFilter.toUpperCase())
          || SA.toUpperCase().includes(this.tabletFilter.toUpperCase())
          || (SB ?? "").toUpperCase().includes(this.tabletFilter.toUpperCase())
      })

      if (this.filteredDatasource.length > 0) {
        this.grid_message = "Slutten av listen";
      } else {
        this.grid_message = "Ingen saker å vise";
      }

    } catch (err: any) {


    }
  }

  /// GRID FUNCTIONS

  public formatDate(val: Date) {
    return new Intl.DateTimeFormat("nb_NO").format(val);
  }

  public formatCurrency(val: string) {
    return parseInt(val, 10).toFixed(2);
  }

  public getFilterValue(column: IgxColumnComponent): any {
    return this._filterValues.has(column) ? this._filterValues.get(column) : null;
  }

  public onKeyDown(event: KeyboardEvent) {
    event.stopImmediatePropagation();
  }

  public onInput(input: any, column: IgxColumnComponent) {
    this._filterValues.set(column, input.value);


    if (input.value === "") {
      this.grid.clearFilter(column.field);
      return;
    }

    let operand = null;
    switch (column.dataType) {
      case GridColumnDataType.Number:
        operand = IgxNumberFilteringOperand.instance().condition("equals");
        break;
      default:
        operand = IgxStringFilteringOperand.instance().condition("contains");
    }
    this.grid.filter(column.field, this.transformValue(input.value, column), operand, column.filteringIgnoreCase);
  }

  public clearInput(column: IgxColumnComponent) {
    this._filterValues.delete(column);
    this.grid.clearFilter(column.field);
  }

  public onClick(event: any) {
    const { target } = event;
    if (!target.isFocused) {
      target.focus();

    }
  }

  public onDateSelected(event: any, column: IgxColumnComponent) {
    this._filterValues.set(column, event);

    this.grid.filter(column.field, event, IgxDateFilteringOperand.instance().condition("equals"),
      column.filteringIgnoreCase);
  }

  public openDatePicker(openDialog: () => void) {
    openDialog();
  }

  private transformValue(value: any, column: IgxColumnComponent): any {
    if (column.dataType === GridColumnDataType.Number) {
      value = parseFloat(value);
    }

    return value;
  }
  public booleanFilteringOperand = IgxBooleanFilteringOperand.instance();
  public onCellClick(event: IGridCellEventArgs) {

    if (this.caseRights === 'NONE') {
      const activity = newActivity({
        message: `Ingen tilgang på området "Saksbehandling".`,
        type: "failure",
        timestamp: new Date()
      })

      this.als.appendActivity(activity)
      return
    }

    if (event.cell.id?.columnID === 8) {
      this.updateShortListFavorite(event.cell.row.data as ICaseAccessLogExtendedBE, !event.cell.value)
      return
    }

    if (this.caseRights === 'OWN_CASES') {
      this.dlxClient.GetCaseLimited(event.cell.row.data.CaseId).subscribe({
        next: (response) => {
          if (this.rights.checkCaseAccessOwnCases({ sa: response.CaseResponsibleId!, sb: response.CaseHandlerId }, this.sys.loggedinUserContact.Id)) {
            this.router.navigate(['/case', response.Id]);
          } else {
            console.log(this.isDesktop);

            if (this.isDesktop) {
              const activity = newActivity({
                message: `Ingen tilgang, du har kun tilgang til egne saker.`,
                type: "failure",
                timestamp: new Date()
              })

              this.als.appendActivity(activity)
            }
            alert("Du har ikke tilgang til denne saken. Du har kun tilgang til egne saker.")
            return
          }
        }
      })

      return
    }
    this.router.navigate(['/case', event.cell.row.data.Id]);
  }

  public handleRowSelection(event: IRowSelectionEventArgs) {
    this.router.navigate(['/case', event.added[0].Id]);
  }

  public mobileNav(id: string) {
    if (this.caseRights === 'NONE') {
      return
    }

    if (this.caseRights === 'OWN_CASES') {
      this.dlxClient.GetCaseLimited(id).subscribe({
        next: (response) => {
          if (this.rights.checkCaseAccessOwnCases({ sa: response.CaseResponsibleId!, sb: response.CaseHandlerId }, this.sys.loggedinUserContact.Id)) {
            this.router.navigate(['/case', response.Id]);
          } else {
            const activity = newActivity({
              message: `Ingen tilgang, du har kun tilgang til egne saker.`,
              type: "failure",
              timestamp: new Date()
            })

            this.als.appendActivity(activity)
            alert("Du har ikke tilgang til denne saken. Du har kun tilgang til egne saker.")
            return
          }
        }
      })

      return
    }
    this.router.navigate(['/case', id]);
  }
  public onRightClick(event: any) {
    event.preventDefault();
  }

  formatDate2(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  public dateFormatOptions = {
    format: 'dd.MM.Y'
  };

  log() {

  }

  public onOpen(evt: IBaseCancelableBrowserEventArgs) {

  }

  public rightClickEvent!: IGridCellEventArgs;

  ctxTarget: ICaseAccessLogBE | null = null;

  forceLowerPermissions = false;

  public rightClick(eventArgs: IGridCellEventArgs) {
    const event = eventArgs.event as PointerEvent;
    event.preventDefault();
    // this.setContext();

    this.dlxClient.GetCaseContactsByCaseId(eventArgs.cell.row.data.CaseId).subscribe({
      next: (response) => {
        if (response.some(i => i.ContactId === this.sys.loggedinUserContact.Id)) {
          this.forceLowerPermissions = false;
          this.setContext();
        } else {
          this.forceLowerPermissions = true;
          this.ctx_content[0][1].label = "Se belastninger";
          this.ctx_content[0][1].leftIcon = "remove_red_eye";
          this.ctx_content[0][1].disabled = false;
        }
        this.rerender();
        this.ctxTarget = eventArgs.cell.row.data;
        const clickPosition = event.clientY - this.grid.nativeElement.getBoundingClientRect().top;
        this.contextmenuX = event.clientX > this.grid.nativeElement.offsetWidth - 150 ? event.clientX - 160 : event.clientX + 10;
        this.contextmenuY = clickPosition - event.offsetY - 70;
        this.contextmenu = true;
        // this.ctx_content[0][1].disabled = true;
      },
      error: () => {
        this.als.appendActivity(newActivity({
          message: "Kunne ikke hente informasjon om saken.",
          type: "failure",
          timestamp: new Date()
        }));
      }
    })
  }

  public disableContextMenu() {
    if (this.contextmenu) {
      this.contextmenu = false;
      this.ctxTarget = null;
    }
  }

  handleSelectedUserChange(event: any) {
  }

  showHourRegistrationDetails(item: IItemSelected, drawer: HTMLDivElement) {
    this.currentHourRegIndex = item.index;
    this.maxHourRegIndex = item.data.length - 1;
    this.hourRegData = item.data;


    if (this.forceLowerPermissions === false) {
      if (this.hasRightToEditHours === true) return;
    }

    this.hourRegistrationDetails = this.hourRegData[this.currentHourRegIndex];
    if (drawer.classList.contains("slide_out")) {
      drawer.classList.replace("slide_out", "slide_in");
    } else {
      drawer.classList.add("slide_in");
    }


  }

  currentHourRegIndex: number = 0;
  maxHourRegIndex: number = 0;
  hourRegData: IHourRegistrationBE[] = [];

  setNextHourReg() {
    if (this.currentHourRegIndex >= this.maxHourRegIndex) {
      this.currentHourRegIndex = 0;
      this.hourRegistrationDetails = this.hourRegData[this.currentHourRegIndex];
    } else {
      this.hourRegistrationDetails = this.hourRegData[this.currentHourRegIndex++];
    }
  }

  setPreviousHourReg() {
    if (this.currentHourRegIndex <= 0) return;
    this.hourRegistrationDetails = this.hourRegData[this.currentHourRegIndex--];
  }

  clearHourRegistrationDetails(drawer: HTMLDivElement) {
    this.hourRegistrationDetails = null;
    this.currentHourRegIndex = 0;
    this.hourRegData = [];
    drawer.classList.replace("slide_in", "slide_out");
  }

}

