
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTime',
  standalone: true
})
export class FormatTimePipe implements PipeTransform {

  transform(value: string): string | null {
    if (!value) return null;
    if (value === '0:0') return null;

    // Split input into hours and minutes
    const parts = value.split(':');
    if (parts.length !== 2) return value;

    let hours = parts[0];
    let minutes = parts[1];

    // Ensure hours and minutes are numeric and valid
    if (isNaN(Number(hours)) || isNaN(Number(minutes))) {
      return value;
    }

    // Convert hours and minutes to numbers
    let hoursNum = Number(hours);
    let minutesNum = Number(minutes);

    // Correctly format hours and minutes
    if (minutesNum < 10) {
      minutes = '0' + minutesNum;
    } else {
      minutes = minutesNum.toString();
    }

    if (hoursNum < 10) {
      hours = '0' + hoursNum;
    } else {
      hours = hoursNum.toString();
    }

    // Return the formatted time
    return `${hours}:${minutes}`;
  }
}
