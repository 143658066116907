import { ICaseAccessLogExtendedBE } from "@datalex-software-as/datalex-client";
import { currencyFormatter } from "./CurrencyFormatter";
import { formatNumberWithThousands } from "./formatNumberWithThousands";

export function sumFee(cases: ICaseAccessLogExtendedBE[]): string {
  let total: number = 0;

  cases.forEach(item => {
    if (item.Fee !== null) {
      total += item.Fee;
    }
  });




  // return currencyFormatter(total);
  return formatNumberWithThousands(total);
}
export function sumCharged(caseAccesLog: ICaseAccessLogExtendedBE[]): string {
  let workingArray: string[] = [];
  let numberArray: number[] = [];
  let hours: number = 0;
  let minutes: number = 0;

  caseAccesLog.forEach(item => {
    if (item.Charged !== null) {
      let newArray = item.Charged.split(':');
      newArray.forEach(item => {
        workingArray.push(item)
      })
    }
  });

  workingArray.forEach((item) => {
    let newItem = Number(item)
    numberArray.push(newItem)
  })

  numberArray.forEach((item, index) => {
    if (index % 2 === 0) {
      hours += item
    } else {
      minutes += item
    }
  })

  if (minutes > 59) {
    let h = Math.floor(minutes / 60);
    let m = minutes % 60;
    hours += h;
    minutes = m;

  }

  let h = String(hours)
  let m = String(minutes)

  if (h.length === 1) {
    h = '0' + h;
  }
  if (m.length === 1) {
    m = '0' + m;
  }


  return `${h}:${m}`;
}

