<div #modalRef></div>
<!-- <div class="grid-container" [ngClass]="{'tabletAndChrome': isTabletAndChrome, 'tabletAndSafari': isTabletAndSafari}"
  (window:click)="disableContextMenu()"> -->
<!-- <div class="grid-container" [ngClass]="{'tablet-height': deviceType === 'Tablet'}"
  (window:click)="disableContextMenu()"> -->
<div class="grid-container" (window:click)="disableContextMenu()">

  <!-- <div class="grid-container" (window:click)="disableContextMenu()"> -->

  <igx-grid igxPreventDocumentScroll #caseAccesLogGrid class="pointer" [data]="caseAccessLog"
    [emptyGridMessage]="emptyGridMessage" emptyFilteredGridMessage="Filtrering gir ingen treff"
    [displayDensity]="deviceService.gridDensity" [autoGenerate]="false" [cellSelection]="'single'"
    [rowSelection]="'single'" (cellClick)="onCellClick($event)" (contextMenu)="rightClick($event)"
    [hideRowSelectors]="true" [allowFiltering]="true">
    <igx-grid-toolbar *ngIf="screenWidth >= 1024">
      <ng-container *ngTemplateOutlet="toolBarTemplate"></ng-container>
    </igx-grid-toolbar>
    <igx-column *ngIf="isMobile" field="Klient / Tittel" [filterCellTemplate]="defaultFilterTemplate">
      <ng-template #compositeTemp igxCell let-cell="cell">
        <div class="contact-container">
          <span><strong>{{cell.row.data.CaseClient}}</strong></span><br />
          <span>{{cell.row.data.Title}}</span>
          <br />
        </div>
      </ng-template>
    </igx-column>
    <igx-column *ngIf="!isMobile" field="CaseClient" width="20%" header="Klient" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate">
    </igx-column>
    <igx-column *ngIf="!isMobile" field="Title" header="Tittel" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>
    <igx-column *ngIf="!isMobile" width="80px" field="SA" header="SA" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>
    <igx-column *ngIf="!isMobile" width="80px" field="SB" header="SB" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>
    <igx-column *ngIf="!isMobile" width="110px" field="Number" header="Saks.nr" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column>
    <!-- <igx-column [width]=" !isMobile ? '110px' : '90px'" field="Logged" header="Logget" [sortable]="true"
      [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>
       -->
    <igx-column [width]=" !isMobile ? '110px' : '90px'" field="Logged" header="Logget" [sortable]="true"
      [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate">

      <ng-template igxCell let-cell="cell">
        <span>{{cell.value | formatTime}}</span>
      </ng-template>

    </igx-column>
    <igx-column [width]="!isMobile ? '110px' : '90px'" field="Charged" header="Belastet" [sortable]="true"
      [dataType]="'string'" [filterCellTemplate]="defaultFilterTemplate">
      <ng-template igxCell let-cell="cell">
        <span>{{cell.value | formatTime}}</span>
      </ng-template>
    </igx-column>


    <!-- <igx-column *ngIf="isDesktop" width="110px" field="Fee" header="Honorar" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate"></igx-column> -->
    <igx-column *ngIf="isDesktop" width="110px" field="Fee" header="Honorar" [sortable]="true" [dataType]="'string'"
      [filterCellTemplate]="defaultFilterTemplate">

      <ng-template igxCell let-cell="cell">
        <span>{{cell.value | norwegianNumberFormat}}</span>
      </ng-template>
    </igx-column>


    <igx-column *ngIf="isDesktop" width="110px" field="IsFavorite" header="Min sak" [editable]="true" [sortable]="true"
      [dataType]="'boolean'" [filterCellTemplate]="defaultFilterTemplate"></igx-column>

    <igx-grid-footer class="grid-footer-container">
      <dlx-grid-item-count [isMobile]="isMobile" [current]="caseAccesLogGrid.totalRowsCountAfterFilter"
        [total]="caseAccessLog.length" />
    </igx-grid-footer>
  </igx-grid>
  <div *ngIf="screenWidth < 1024" class="bottom-toolbar">
    <ng-container *ngTemplateOutlet="toolBarTemplate"></ng-container>
  </div>
</div>


<grid-context-menu *ngIf="contextmenu" #gridContextMenu [x]="contextmenuX" [y]="contextmenuY" [content]="ctx_content">
</grid-context-menu>

<div>
  <ng-container #outlet [ngTemplateOutlet]="content">
  </ng-container>
</div>

<ng-template #content>
  <igx-dialog #RegiterHourModal [closeOnOutsideSelect]="false">
    <div>
      <igx-dialog-title>
        <div class="dialog-title-container">
          <div class="dialog-title">{{ caseClosedForTimeRegistration ? 'Saken er lukket.' : hasRightToEditHours ?
            "Timeregistrering" : "Timeregistreringer"}} </div>
          <span class="close-icon" (click)="RegiterHourModal.close()">+</span>
        </div>
      </igx-dialog-title>
      @if (caseClosedForTimeRegistration) {
      <div class="case-closed-message">
        <h2>Saken er ikke åpen for registrering av tid.</h2>
      </div>
      } @else {
      <app-hours-registration class="hour-registration-dialog-wrapper" *ngIf="clickedCellRowData"
        [data]="clickedCellRowData" (itemSelected)="showHourRegistrationDetails($event, detailsDrawer)"
        [forceLowerPermissions]="forceLowerPermissions" />

      <div #detailsDrawer class="hourRegistrationDetails-drawer">
        <div class="details-header">
          @if(clickedCellRowData && hourRegistrationDetails) {
          <div class="details-title">{{
            clickedCellRowData.Title +
            " - " +
            hourRegistrationDetails!.HourRegistrationTypeName +
            " - " +
            hourRegistrationDetails!.Fee +
            " - " +
            hourRegistrationDetails!.InvoiceTypeDescription}}</div>
          <div class="details-actions align-center">
            <span class="close-icon" (click)="clearHourRegistrationDetails(detailsDrawer)">+</span>
          </div>
          }

        </div>
        <div class="details-body">
          @if(hourRegistrationDetails) {

          <div class="row1">
            <igx-input-group displayDensity="compact" type="border">
              <input igxInput class="search-input" name="CompletedDate" autocomplete="off" [value]="'19.06.24'"
                type="text" readonly />
              <label igxLabel for="CompletedDate">Dato:</label>
            </igx-input-group>

            <igx-input-group displayDensity="compact" type="border">
              <input igxInput class="search-input" name="CompletedByName" autocomplete="off"
                [value]="hourRegistrationDetails.CompletedByName" type="text" readonly />
              <label igxLabel for="CompletedByName">Utført av:</label>
            </igx-input-group>
          </div>
          <div class="row2">
            <igx-input-group displayDensity="compact" type="border">
              <input igxInput class="search-input" name="HourRegistrationTypeCode" autocomplete="off"
                [value]="hourRegistrationDetails.HourRegistrationTypeCode" type="text" readonly />
              <label igxLabel for="HourRegistrationTypeCode">Kode:</label>
            </igx-input-group>
            <igx-input-group displayDensity="compact" type="border">
              <input igxInput class="search-input" name="HourRegistrationTypeName" autocomplete="off"
                [value]="hourRegistrationDetails.HourRegistrationTypeName" type="text" readonly />
              <label igxLabel for="HourRegistrationTypeName">Type:</label>
            </igx-input-group>
          </div>
          <div class="row3">
            <igx-input-group displayDensity="compact" type="border">
              <textarea igxInput class="search-input" name="Description" autocomplete="off"
                [value]="hourRegistrationDetails.Description" [rows]="4" type="text" readonly></textarea>
              <label igxLabel for="Description">Beskrivelse:</label>
            </igx-input-group>
          </div>
          <div class="row4">
            <igx-input-group displayDensity="compact" type="border">
              <input igxInput class="search-input" name="WorkedMinuttes" autocomplete="off"
                [value]="hourRegistrationDetails.WorkedMinuttes" type="text" readonly />
              <label igxLabel for="WorkedMinuttes">Tid:</label>
            </igx-input-group>

            <igx-input-group displayDensity="compact" type="border">
              <input igxInput class="search-input" name="Rate" autocomplete="off" [value]="hourRegistrationDetails.Rate"
                type="text" readonly />
              <label igxLabel for="Rate">Sats:</label>
            </igx-input-group>

            <igx-input-group displayDensity="compact" type="border">
              <input igxInput class="search-input" name="Fee" autocomplete="off" [value]="hourRegistrationDetails.Fee"
                type="text" readonly />
              <label igxLabel for="Fee">Honorar:</label>
            </igx-input-group>
            <igx-input-group displayDensity="compact" type="border">
              <input igxInput class="search-input" name="InvoiceTypeDescription" autocomplete="off"
                [value]="hourRegistrationDetails.InvoiceTypeDescription" type="text" readonly />
              <label igxLabel for="InvoiceTypeDescription">Fakturakode:</label>
            </igx-input-group>
          </div>
          }

        </div>
        <div class="details-actions justify-end">
          <span class="details-action" (click)="setPreviousHourReg()">Forrige</span>
          <span class="details-display-counter">{{currentHourRegIndex + 1 + " / " + (maxHourRegIndex + 1)}}</span>
          <span class="details-action" (click)="setNextHourReg()">Neste</span>
        </div>
      </div>
      }
    </div>
  </igx-dialog>
</ng-template>


<ng-template #toolBarTemplate>
  <div class="toolbar-actions_left">

    @if( deviceOrientation === 'portrait' && deviceType !== 'Desktop' ) {
    <div *ngIf="sys.allEmployees" class="simple-select-wrapper employees_wrapper">
      <label #employeeLabel igxLabel class="simple-select-label simple-select-label--selected"
        style="background-color: #fafafa;">Medarbeider:</label>
      <igx-simple-combo #select id="completedBy" defaultValue="selectedUser" [data]="sys.allEmployees"
        displayDensity="compact" (opening)="combo.comboOpening(employeeLabel)"
        (closing)="combo.comboClosing(employeeLabel, selectedUser)" [displayKey]="'ContactName'" [valueKey]="'Id'"
        [(ngModel)]="selectedUser" [type]="'border'" (ngModelChange)="selectedUserChange($event)"
        [overlaySettings]="upwardPositionSettings">
      </igx-simple-combo>
    </div>

    <div class="toolbar-actions_right">
      <div class="date-picker spacer">
        <igx-date-picker #datePicker [displayDensity]="'compact'" type="border" todayButtonLabel="I dag"
          cancelButtonLabel="Avbryt" [value]="formatDate2(date)" (valueChange)="changeDate($event)"
          [overlaySettings]="upwardPositionSettings">
          <label igxLabel for="CompletedDate">Dato</label>
          <igx-icon class="date-custom-suffix" igxPrefix (click)="prevDay()">keyboard_arrow_left</igx-icon>
          <igx-icon igxSuffix (click)="datePicker.open()">today</igx-icon>
          <igx-icon class="date-custom-suffix" igxSuffix (click)="nextDay()">keyboard_arrow_right</igx-icon>
        </igx-date-picker>
      </div>

      <ng-container *ngIf="getAllActiveCases; then allDisabled; else allActive">
      </ng-container>


      <div class="caseAccessLog-control-wrapper total-charged-wrapper">
        <app-heading class="spacer-s" [value]="'Totalt belastet:'"></app-heading>
        <input class="spacer-s desplayInput" [(ngModel)]="totalCharged" name="totalChargedTime" type="text"
          disabled="true">
        <input class="spacer-s desplayInput text-align-right" [(ngModel)]="totalFee" name="totalChargedFee" type="text"
          disabled="true">
      </div>
    </div>


    }@else {
    <div *ngIf="sys.allEmployees" class="simple-select-wrapper employees_wrapper">
      <label #employeeLabel igxLabel class="simple-select-label simple-select-label--selected"
        style="background-color: #fafafa;">Medarbeider:</label>
      <igx-simple-combo #select id="completedBy" defaultValue="selectedUser" [data]="sys.allEmployees"
        displayDensity="compact" (opening)="combo.comboOpening(employeeLabel)"
        (closing)="combo.comboClosing(employeeLabel, selectedUser)" [displayKey]="'ContactName'" [valueKey]="'Id'"
        [(ngModel)]="selectedUser" [type]="'border'" (ngModelChange)="selectedUserChange($event)">
      </igx-simple-combo>
    </div>

    <div class="toolbar-actions_right">
      <div class="date-picker spacer">
        <igx-date-picker #datePicker [displayDensity]="'compact'" type="border" todayButtonLabel="I dag"
          cancelButtonLabel="Avbryt" [value]="formatDate2(date)" (valueChange)="changeDate($event)">
          <label igxLabel for="CompletedDate">Dato</label>
          <igx-icon class="date-custom-suffix" igxPrefix (click)="prevDay()">keyboard_arrow_left</igx-icon>
          <igx-icon igxSuffix (click)="datePicker.open()">today</igx-icon>
          <igx-icon class="date-custom-suffix" igxSuffix (click)="nextDay()">keyboard_arrow_right</igx-icon>
        </igx-date-picker>
      </div>

      <ng-container *ngIf="getAllActiveCases; then allDisabled; else allActive">
      </ng-container>


      <div class="caseAccessLog-control-wrapper total-charged-wrapper">
        <app-heading class="spacer-s" [value]="'Totalt belastet:'"></app-heading>
        <input class="spacer-s desplayInput" [(ngModel)]="totalCharged" name="totalChargedTime" type="text"
          disabled="true">
        <input class="spacer-s desplayInput text-align-right" [(ngModel)]="totalFee" name="totalChargedFee" type="text"
          disabled="true">
      </div>
    </div>

    }

  </div>

</ng-template>

<ng-template #defaultFilterTemplate igxFilterCellTemplate let-column="column">
  <grid-filter-input [grid]="caseAccesLogGrid" [column]="column"></grid-filter-input>
</ng-template>


<ng-template #allActive>
  <button class="my-cases-button spacer" igxButton="raised" igxButtonColor="white" [style.background]="'#575757'"
    igxRipple="white" (click)="toggleAllActive()">ALLE AKTIVE</button>
</ng-template>
<ng-template #allDisabled>
  <button class="my-cases-button spacer" igxButton="raised" igxButtonColor="white" [style.background]="'#AEC965'"
    igxRipple="white" (click)="toggleAllActive()">ALLE AKTIVE</button>
</ng-template>


<ng-template #combinedHeader>
  <th class="td-medium">Klient</th>
  <th class="td-dynamic">Tittel</th>
</ng-template>
<ng-template #rowHeader>
  <th class="td-dynamic">Klient / Tittel</th>
</ng-template>